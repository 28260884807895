import axios from 'axios';

import {setAlert} from './alert';
import setHeader from '../utils/setHeader';
import config from '../config';

import {
  GET_LINKS,
  GET_LINK,
  ADD_LINK,
  UPDATE_LINK,
  DELETE_LINK,
  ERROR_LINK,
} from './types';

export const getLinks = (user) => async (dispatch) => {
  try {
    const res = await axios.get(
      config.URL.BACKEND + config.API_VERSION + 'list/' + user.slack_id
    );
    dispatch({type: GET_LINKS, payload: res.data.data});
  } catch (error) {
    dispatch({
      type: ERROR_LINK,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const getLink = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      config.URL.BACKEND + config.API_VERSION + 'id/' + id
    );
    dispatch({type: GET_LINK, payload: res.data});
  } catch (error) {
    dispatch({
      type: ERROR_LINK,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const updateLink = (formData, id) => async (dispatch) => {
  try {
    const reqConfig = setHeader();
    const res = await axios.put(
      config.URL.BACKEND + config.API_VERSION,
      {body: formData, id: id},
      reqConfig
    );
    dispatch({
      type: UPDATE_LINK,
      payload: res.data.data,
    });
    dispatch(setAlert(res.data.msg, 'success'));
    window.location.href = '/';
  } catch (error) {
    const errors = error.response.data;
    if (errors) {
      dispatch(setAlert(errors.msg, 'error'));
    }
    dispatch({
      type: ERROR_LINK,
      payload: {msg: error.response.data.msg, status: error.response.status},
    });
  }
};

export const deleteLink = (id) => async (dispatch) => {
  try {
    const reqConfig = setHeader();
    const res = await axios.delete(
      config.URL.BACKEND + config.API_VERSION + id,
      reqConfig
    );
    dispatch({
      type: DELETE_LINK,
      payload: id,
    });

    dispatch(setAlert(res.data.msg, 'success'));
    window.location.href = '/';
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      dispatch(setAlert(errors.msg, 'error'));
    }
    dispatch({
      type: ERROR_LINK,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};

export const addLink = (formData) => async (dispatch) => {
  try {
    const reqConfig = setHeader();
    const res = await axios.post(
      config.URL.BACKEND + config.API_VERSION,
      formData,
      reqConfig
    );
    dispatch({
      type: ADD_LINK,
      payload: res.data.data,
    });
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    const errors = error.response.data;
    if (errors) {
      dispatch(setAlert(errors.msg, 'error'));
    }
    dispatch({
      type: ERROR_LINK,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
