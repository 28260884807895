import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import config from '../../config';

const PrivateRoute = ({
  component: Component,
  auth: {isAuthenticated, loading},
  ...rest
}) => {
  const CustomRedirect = ({rest, props}) => {
    if (rest.path.includes('shortcut')) {
      return <Component {...props} />;
    } else {
      window.location.href = config.URL.AUTH + '?src=' + window.location.href;
      return null;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <CustomRedirect rest={rest} props={props} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PrivateRoute);
