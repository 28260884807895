import React, {useEffect, Fragment} from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';

import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';

import PrivateRoute from './components/routing/PrivateRoute';
import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import EditURL from './components/url/Edit';

import {loadUser} from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import store from './store';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    padding: theme.spacing(6, 0),
  },
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
if (window.location.href.includes('?tkn')) {
  let token = window.location.href.split('?tkn=');
  token = token[1];
  localStorage.setItem('token', token);
}

if (localStorage.token) {
  setAuthToken(localStorage.token);
  store.dispatch(loadUser());
} else {
  // window.location.href = config.AUTH_URL + '?src=' + window.location.href;
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const classes = useStyles();
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <div className={classes.root}>
            <CssBaseline />
            <Header />
            <VerticalNav />
            <main className={classes.content}>
              <Toolbar />
              <Alert />
              <Switch>
                <PrivateRoute exact path='/' component={Dashboard} />
                <PrivateRoute exact path='/edit/:id' component={EditURL} />
              </Switch>
            </main>
          </div>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
