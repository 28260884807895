import {
  GET_LINKS,
  GET_LINK,
  ADD_LINK,
  UPDATE_LINK,
  DELETE_LINK,
  ERROR_LINK,
  CLEAR_LINK,
} from '../actions/types';

const initialState = {
  link: {},
  links: [],
  loading: true,
  error: {},
};

export default function link(state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_LINKS:
      return {
        ...state,
        link: {},
        links: payload,
        loading: false,
      };
    case GET_LINK:
      return {
        ...state,
        link: payload,
        links: [],
        loading: false,
      };
    case ADD_LINK:
      return {
        ...state,
        link: {},
        links: [payload, ...state.links],
        loading: false,
      };
    case UPDATE_LINK:
      return {
        ...state,
        link: payload,
        links: [],
        loading: false,
      };
    case DELETE_LINK:
      return {
        ...state,
        link: {},
        links: [],
        loading: false,
      };
    case CLEAR_LINK:
      return {
        ...state,
        link: {},
        links: [],
        loading: false,
      };
    case ERROR_LINK:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
