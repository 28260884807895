import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import {addLink} from '../../actions/link';
import {getUserMeta} from '../../actions/user-metas';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const Form = ({
  addLink,
  getUserMeta,
  auth: {user},
  user_metas: {user_meta},
}) => {
  useEffect(() => {
    if (user && Object.entries(user_meta).length === 0) {
      getUserMeta(user._id);
    }
  }, [getUserMeta, user, user_meta]);

  const classes = useStyles();

  const [formData, setFormData] = useState({
    url: '',
  });

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    formData.userId = user_meta.slack_id;
    addLink(formData);
    setFormData({...formData, url: ''});
  };

  return (
    <Fragment>
      <Typography variant='h6' color='textSecondary' paragraph>
        Create something short and recognizable short link. Make it short and
        sweet, but not too short so folks don&apos;t simply skip over it
        entirely.
      </Typography>
      <form
        align='center'
        className={classes.form}
        noValidate
        onSubmit={(e) => onSubmit(e)}
      >
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='url'
          label='Long URL'
          name='url'
          autoComplete='url'
          autoFocus
          value={formData.url}
          onChange={(e) => onChange(e)}
        />
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          startIcon={<SendIcon />}
          type='submit'
        >
          Shorten
        </Button>
      </form>
    </Fragment>
  );
};

Form.propTypes = {
  addLink: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user_metas: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user_metas: state.user_metas,
});

export default connect(mapStateToProps, {addLink, getUserMeta})(Form);
