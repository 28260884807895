import React, {Fragment} from 'react';

import Form from '../link/Form';
import Collection from '../link/Collection';

const Dashboard = (props) => {
  return (
    <Fragment>
      <Form></Form>
      <Collection></Collection>
    </Fragment>
  );
};

export default Dashboard;
