import axios from 'axios';
import {GET_USER_META, USER_META_ERROR} from './types';
import config from '../config';

export const getUserMeta = (id) => async (dispatch) => {
  try {
    const res = await axios.get(config.URL.CLOCKIN + `user-metas/user/${id}`);
    dispatch({type: GET_USER_META, payload: res.data.data});
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      // errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: USER_META_ERROR,
      payload: {msg: error.response, status: error.response.status},
    });
  }
};
