import React, {Fragment, useEffect} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import config from '../../config';

import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from '@material-ui/core';

import {getUserMeta} from '../../actions/user-metas';
import {getLinks, deleteLink} from '../../actions/link';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  const toReturn =
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  return toReturn;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const returnIt = stabilizedThis.map((el) => el[0]);
  return returnIt;
}

const headCells = [
  {
    id: 'short_id',
    numeric: false,
    label: 'Link',
  },
  {
    id: 'date_added',
    numeric: false,
    label: 'Date Created',
  },
  {
    id: 'hits',
    numeric: true,
    label: 'Hit',
  },
];

const EnhancedTableHead = (props) => {
  const {classes, order, orderBy, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key='action'>Action</TableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  originalLinkWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '400px',
  },
}));

const Collection = ({
  link: {links, loading},
  auth: {user},
  user_metas: {user_meta},
  getLinks,
  getUserMeta,
  deleteLink,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date_added');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onClick = (e) => {
    deleteLink(e.currentTarget.dataset.id);
  };
  useEffect(() => {
    if (user && Object.entries(user_meta).length === 0) {
      getUserMeta(user._id);
    }
    if (Object.entries(user_meta).length !== 0) {
      getLinks(user_meta);
    }
  }, [getLinks, getUserMeta, user, user_meta]);

  return (
    <Fragment>
      <Table size='small'>
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(links, getComparator(order, orderBy)).map((link) => {
            return (
              <TableRow tabIndex={-1} key={link._id}>
                <TableCell>
                  <Link
                    target='_blank'
                    href={config.URL.BACKEND + link.short_id}
                    variant='body1'
                  >
                    {config.URL.BACKEND + link.short_id}
                  </Link>{' '}
                  <CopyToClipboard text={config.URL.BACKEND + link.short_id}>
                    <IconButton color='primary' aria-label='Copy the link'>
                      <FileCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                  <br />
                  <div className={classes.originalLinkWrapper}>
                    <Link
                      target='_blank'
                      href={link.url}
                      variant='body2'
                      color='textSecondary'
                    >
                      {link.url}
                    </Link>
                  </div>
                </TableCell>
                <TableCell>
                  <small>
                    {new Intl.DateTimeFormat('id-ID', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                    }).format(new Date(link.date_added))}
                  </small>
                </TableCell>
                <TableCell>
                  <small>{link.hits}</small>
                </TableCell>
                <TableCell>
                  <Link href={'edit/' + link.short_id}>
                    <Button
                      data-id={link._id}
                      size='small'
                      variant='contained'
                      color='secondary'
                    >
                      Edit
                    </Button>
                  </Link>
                  <Button
                    data-id={link.short_id}
                    size='small'
                    variant='contained'
                    color='default'
                    onClick={(e) => onClick(e)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

Collection.propTypes = {
  getLinks: PropTypes.func.isRequired,
  deleteLink: PropTypes.func.isRequired,
  link: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  user_metas: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  link: state.link,
  auth: state.auth,
  user_metas: state.user_metas,
});

export default connect(mapStateToProps, {getUserMeta, getLinks, deleteLink})(
  withRouter(Collection)
);
