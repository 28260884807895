export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const GET_LINKS = 'GET_LINKS';
export const GET_LINK = 'GET_LINK';
export const ADD_LINK = 'ADD_LINK';
export const UPDATE_LINK = 'UPDATE_LINK';
export const DELETE_LINK = 'DELETE_LINK';
export const ERROR_LINK = 'ERROR_LINK';
export const CLEAR_LINK = 'CLEAR_LINK';

export const GET_USER_META = 'GET_USER_META';
export const GET_USER_METAS = 'GET_USER_METAS';
export const UPDATE_USER_META = 'UPDATE_USER_META';
export const USER_META_ERROR = 'USER_META_ERROR';
export const CLEAR_USER_META = 'CLEAR_USER_META';
