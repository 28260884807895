import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';

import {updateLink, getLink} from '../../actions/link';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const Edit = ({link: {link, loading}, getLink, updateLink, match}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    url: '',
    short_id: '',
    id: '',
  });
  const {url, short_id, id} = formData;
  useEffect(() => {
    if (!url) {
      getLink(match.params.id);
      setFormData({
        url: loading || !link.url ? '' : link.url,
        short_id: loading || !link.short_id ? '' : link.short_id,
        id: loading || !link._id ? '' : link._id,
      });
    }
  }, [getLink, match.params.id, link, loading, url]);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateLink(formData, id);
  };
  return (
    <Fragment>
      <form
        align='center'
        className={classes.form}
        noValidate
        onSubmit={(e) => onSubmit(e)}
      >
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='url'
          label='Long URL'
          name='url'
          autoComplete='url'
          autoFocus
          onChange={(e) => onChange(e)}
          value={url}
        ></TextField>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='short_id'
          label='Short URL'
          name='short_id'
          autoComplete='short_id'
          autoFocus
          onChange={(e) => onChange(e)}
          value={short_id}
        ></TextField>
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          startIcon={<SendIcon />}
          type='submit'
        >
          Save
        </Button>
      </form>
    </Fragment>
  );
};

Edit.propTypes = {
  getLink: PropTypes.func.isRequired,
  updateLink: PropTypes.func.isRequired,
  link: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  link: state.link,
});

export default connect(mapStateToProps, {getLink, updateLink})(Edit);
